window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');

    var bootstrap = require('bootstrap');
    window.bootstrap = bootstrap;

    require('select2');
} catch (e) { }

// Datatables
require('datatables.net-bs5');
require('datatables.net-responsive-bs5');
require('datatables.net-rowgroup-bs5');
require('datatables.net-scroller-bs5');
require('datatables.net-plugins/sorting/date-eu.js');
$.fn.dataTable.ext.errMode = 'throw';
$('.datatables').on( 'error.dt', function ( e, settings, techNote, message ) {
    window.location.replace('/login');
}).DataTable();

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'c468779a8d1ea4338574',
    cluster: 'sa1',
    forceTLS: true
});

//Toastr
import toastr from 'toastr';
window.toastr = toastr;
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}
